html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Source Sans Pro', -apple-system, sans-serif;
  max-width: 100vw;
}
body {
  overflow-x: hidden;
}
body:before {
  content: '';
  background-size: contain;
  background-image: url('~/public/images/background.png');
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(25px);
  top: -15px;
}
@media (max-width: 768px) {
  /* Scale will break layout on mobile */
  body:before {
    transform: scaleX(1); /*To remove white border*/
  }
}
body .page {
  background: rgba(29, 28, 47, 0.5);
  /* backdrop-filter: blur(40px); */
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.redeem-section {
	height: calc(100vh - 4rem);
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000000 !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000000 !important;
}

.dots-white.slick-dots li.slick-active button:before,
.dots-white.slick-dots li button:before {
  color: white;
}

.carousel .thumb {
  height: 80px;
  border-color: #333 !important;
}

.carousel .thumbs-wrapper {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border-color: #fff !important;
}

.no-auction .single-owner,
.no-auction .artist,
.no-auction .valued,
.no-auction .available-for {
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 23px;
}

.no-auction .artist {
  margin-top: 0;
  margin-bottom: 17px;
}

.no-auction .valued {
  margin-top: 0;
  margin-bottom: 8px;
}

.no-auction .available-for {
  margin-top: 0;
  margin-bottom: 10px;
}

.no-auction .price {
  font-size: 24px;
  line-height: 30px;
}

.no-auction .appraised,
.no-auction .appraised-2 {
  font-size: 14px;
  line-height: 18px;
  color: #696969;
}

.no-auction .appraised-2 {
  margin-bottom: 14px;
}

.no-auction .btn {
  margin-top: 18px;
  margin-bottom: 26px;
}

.no-auction .social {
  margin-top: 19px;
  margin-bottom: 14px;
}

/*.art-work-detail {*/
/*    padding: 18px 25px 20px 22px !important;*/
/*}*/

.art-work-detail .close-icon {
  top: 22px;
  right: 16px;
}

.art-work-detail .bid-title {
  color: #1b1b1b;
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 17px;
}

.art-work-detail .bid-current,
.activity .activity-title {
  font-size: 24px;
  line-height: 30px;
  margin: 0;
}

.art-work-detail .bid-no-reserve {
  color: #029c64;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 16px;
}

.art-work-detail .bid-next-minimum,
.art-work-detail .bid-quick,
.art-work-detail .direct-bid {
  font-size: 14px;
  line-height: 18px;
  margin-top: 13px;
  margin-bottom: 4px;
}

.art-work-detail .bid-next-price {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 4px;
}

.art-work-detail .bid-more-info {
  color: #898a8d;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 14px;
}

.art-work-detail .bid-quick {
  margin-top: 15px;
  margin-bottom: 12px;
}

.art-work-detail .direct-bid {
  margin-top: 18px;
  margin-bottom: 7px;
}

input.bid-price[type='number'] {
  height: 44px;
  border: 1px solid #f196be;
}

.art-work-detail button,
.art-work-detail input[type='submit'] {
  height: 44px;
}

.activity {
  padding: 20px 30px 32px 22px;
  margin-top: 44px;
}

.activity .activity-title {
  margin-bottom: 16px;
}

.activity .activity-item {
  margin-bottom: 19px;
}

.activity .activity-item .cancel-button {
  display: none;
}

.activity .activity-item:hover .cancel-button {
  display: inline-block;
}

.activity .activity-item:last-child {
  margin-bottom: 16px;
}

.activity .activity-item p,
.activity .activity-item span {
  font-size: 18px;
  line-height: 23px;
}

.activity .activity-item p {
  margin-bottom: 4px;
}

.activity p.payment-title {
  font-size: 18px;
  line-height: 23px;
  margin-top: 19px;
  margin-bottom: 11px;
}

.activity p.payment-info {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 18px;
}

.activity p.payment-option,
.activity p.social {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 15px;
}

.carousel .slide iframe {
  margin: 0 !important;
  width: 100% !important;
}

.summary {
  padding-left: 14px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin-bottom: 16px;
}

.summary .summary-item {
  padding-top: 12px;
  padding-bottom: 14px;
}

.artwork-table {
  border: 1px solid #fff288;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 16px;
  overflow: hidden;
}

/*.slick-track { 	display: flex !important; }*/
/*.slick-slide { 	height: auto !important;}*/

/*.slick-slide > div { height: 100% }*/

.carousel .slide iframe {
  margin: 0 !important;
  width: 100% !important;
}

.artwork-table.has-data {
  /*border: none;*/
  padding: 0;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
}

.my-balance {
  margin-bottom: 16px;
  padding: 16px 18px 19px 22px;
}

.my-balance button {
  border-radius: 4px;
  height: 38px;
}

.my-active-bids {
  padding: 19px 18px 22px 22px;
}

.rdt_TableHeader,
#pagination-last-page,
#pagination-first-page {
  display: none !important;
}

.rdt_TableHeadRow {
  text-transform: uppercase;
  background: #f9f9fb !important;
}

.rdt_TableHeadRow .rdt_TableCol {
  color: #8e8fa3;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
}

.rdt_Pagination svg {
  top: 7px !important;
  right: 5px !important;
}

.rdt_TableCell {
  /*display: block !important;*/
  /*padding-top: 10px;*/
}

.rdt_TableCell:first-child div[data-tag='allowRowEvents'] {
  width: 100% !important;
}

.mobile .rdt_TableHead {
  height: 1px !important;
}

.custom-bg-yellow {
  background-color: #fcff5f;
}

.bg-collection {
  background-image: url('/images/Collection Header.png');
  background-color: black;
  background-position: -10px -10px;
  background-size: calc(100% + 20px) calc(100% + 20px);
}

.header-collection {
  color: #f1ff56;
}

.new-primary-bg {
  background-color: #f1ff56 !important;
}

.btn-header-menu:hover,
.btn-header-menu.btn-header-menu--active {
  color: #f1ff56;
  border-color: #f1ff56;
}

.bg-login-button {
  transition: 0.2s background-color;
  background: #fff !important;
}
.bg-login-button:hover {
  background: rgba(255, 255, 255, 0.8) !important;
}

.new-primary-button {
  transition: 0.2s background-color;
  background-color: #f1ff56 !important;
}
.new-outline-primary-button {
  transition: 0.2s background-color;
  background-color: transparent !important;
  border: 1px solid #d2d2d2 !important;
  color: #f1ff56 !important;
}
.new-primary-button:hover {
  background-color: #e0f500 !important;
}
.new-outline-primary-button:hover {
  color: black !important;
  border: 1px solid #f1ff56 !important;
  background-color: #f1ff56 !important;
}
.new-primary-button[disabled] {
  cursor: default;
  opacity: 0.5;
}
.new-primary-button[disabled]:hover {
  background-color: #f1ff56 !important;
}

.bg-violet {
  background: #25273a;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}

.bg-dark {
  background-color: #131220;
}

.new-text-color,
.header-text-comming:hover {
  color: #f1ff56;
}

.text-gradient-pink-blue {
  background: -webkit-linear-gradient(left, #873ee3, #2589fe);
  background: -o-linear-gradient(right, #873ee3, #2589fe);
  background: -moz-linear-gradient(right, #873ee3, #2589fe);
  background: linear-gradient(to right, #873ee3, #2589fe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.support-item {
  height: 162px;
  display: flex !important;
}

.support-item img {
  max-height: 60px;
}

.border-nfts {
  border: 2px solid #545454;
  border-top: none;
  border-radius: 0 0 6px 6px;
}

.custom-arrow .slick-arrow,
.custom-arrow .slick-arrow:hover {
  background: #25273a;
  border: 1px solid #32365c;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  z-index: 10;
}

.custom-arrow .slick-arrow:before {
  content: '';
  width: 40px;
  height: 40px;
  border: 3px solid #f1ff56;
}

.border-yellow-new {
  border-color: #f1ff56 !important;
}

.border-7 {
  border-width: 7px !important;
}

.bg-gray-new {
  background-color: #2d2b43 !important;
}
.text-gray-new {
  color: #625f80 !important;
}

hr {
  border-color: #615e85;
}
.code-nofitication-box {
  background-color: #464b7e;
}

.text-green-new {
  color: #22d091;
}
.member-background {
  background: linear-gradient(180deg, rgba(135, 62, 227, 0.5) 75.52%, rgba(37, 137, 254, 0.5) 100%);
}
.dark-table {
  background: transparent;
  border-radius: 6px;
  padding: 10px 16px;
  margin-bottom: 16px;
  overflow: hidden;
  max-width: calc(100vw - 32px);
}
.dark-table.has-data {
  /*border: none;*/
  padding: 0;
  border-radius: 6px;
}
.dark-table .rdt_TableHeadRow {
  background: #25273a !important;
  min-height: 30px;
  color: #8e8fa3;
  border-bottom: none;
}
.dark-table .rdt_TableRow .rdt_TableCell {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
input[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e") !important;
}

input[type='checkbox']:focus {
  box-shadow: none !important;
}

input.no-shadow:focus {
  box-shadow: none !important;
}

.hover-overflow {
  overflow-y: scroll;
}
.hover-overflow::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.hover-overflow::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.hover-overflow::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}
/* Handle */
.hover-overflow:hover::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
/* Handle on hover */
.hover-overflow::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.customMentionInput {
  height: 2.5rem;
  padding-right: 2.5rem;
}
.customMentionInput__input {
  display: flex !important;
  width: 100% !important;
  border-radius: 0.75rem !important;
  height: 2.5rem !important;
  padding-right: 2.5rem !important;
  padding-left: 1rem !important;
  background-color: #212121 !important;
  color: #fff !important;
  border-color: #5f5f5f !important;
}

.customMentionInput__input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.customMentionInput__suggestions__list {
  border: 0.5px solid #6b72807a;
  font-size: inherit;
  position: absolute;
  border-radius: 0.25rem;
  bottom: calc(100% + 2rem);
  min-width: 200px;
  max-width: 500px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 9999;
  background-color: black;
  left: 10px;
}

.customMentionInput__suggestions__item {
  padding: 7px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  background-color: transparent;
  /* color: white; */
  color: #d4d4d8;
}

.customMentionInput__suggestions__item--focused {
  background-color: #2d8fed1a;
  color: #2d8fedcc;
}

.customMentionInput--singleLine .customMentionInput__control {
  display: inline-block;
  max-width: 100%;
}

.customMentionInput--singleLine .customMentionInput__highlighter {
  display: flex !important;
  width: 100% !important;
  border: 1px solid !important;
  border-radius: 0.75rem !important;
  height: 2.5rem !important;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 2.5rem;
  padding-left: 1rem;
}
.customMentionInput--singleLine
  .customMentionInput__highlighter
  .customMentionInput__highlighter__substring {
  color: #b9bb35 !important;
  font-weight: inherit;
}

.customInputChat {
  min-width: calc(100% - 110px);
  max-width: calc(100% - 110px);
}

/*toolkit page*/
@media all and (min-width: 1200px) {
  .section-launch-step .launch-step-outer-ctn {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
}

.section-launch-step .launch-step-ctn {
  max-width: 288px;
}

@media all and (min-width: 700px) {
  .section-launch-step .description {
    max-width: 500px;
  }
}
/*end toolkit page*/

/*toolkit success page*/
.toolkit-success-ctn img {
  max-width: 300px;
}

@media all and (min-width: 1024px) {
  .toolkit-success-ctn img {
    max-width: 450px;
  }
}
/*end toolkit success page*/

.veriff-description {
  display: none !important;
}

.main-section {
  background-image: url('/images/main-section-background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-blur {
  background-image: url('/images/background-blur.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
