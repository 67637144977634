@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply border font-semibold rounded border-transparent text-2xl leading-6 font-medium shadow-sm text-white bg-black hover:bg-gray-600 disabled:bg-gray-500 disabled:cursor-not-allowed;
  }

  .btn-secondary {
    @apply border font-semibold rounded border-black border-transparent text-2xl leading-6 font-medium shadow-sm text-black bg-white hover:text-white hover:bg-gray-900 disabled:bg-gray-500 disabled:cursor-not-allowed;
  }

  .btn-highlight-primary {
    @apply border rounded border-transparent leading-6 px-4 py-1 font-medium shadow-sm text-white bg-pink-600 hover:bg-pink-700 disabled:bg-gray-500 disabled:cursor-not-allowed;
  }

  .btn-highlight-secondary {
    @apply border font-semibold rounded border-black border-transparent text-2xl leading-6 font-medium shadow-sm text-black bg-white hover:text-white hover:bg-gray-900 disabled:bg-gray-500 disabled:cursor-not-allowed;
  }

  .btn-highlight-third {
    @apply border rounded border-transparent leading-6 px-4 py-1 font-medium text-pink-600 hover:text-pink-700 disabled:text-gray-500 disabled:cursor-not-allowed;
  }

  .form-control {
    @apply appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 2xl:disabled:bg-gray-200;
  }

  .form-label {
    @apply block font-medium text-gray-700;
  }

  p {
    @apply mb-4;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-header;
  }
}

@variants dark {
  .bg-content {
    background-color: #131220;
  }
}
